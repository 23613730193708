<template>
    <div class="SetPromotion">
        <div class="SetPromotionHeader">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>营销工具</el-breadcrumb-item>
                <el-breadcrumb-item>套装促销</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="headerLeft">
                <p>套装促销</p>
                <div class="headerLeftBtn">
<!--                    <el-button class="btn" @click="endPromotion">结束推广</el-button>-->
                    <el-button class="btn-red" @click="toPromotionAdd" :disabled="tableData.length > 0">新建促销</el-button>
                </div>
            </div>
        </div>
        <div class="SetPromotionTable">
            <el-table :data="tableData" style="width: 100%;flex: 1;margin-top: 20px;border: 1px solid #DCDCDC;height: calc(100vh - 252px);overflow-y: auto"
                      :cell-style="{height: '70px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}"
                      :header-cell-style="{fontWeight: '500', color: '#666666', background: '#F5F5F5',height: '46px'}">
                <el-table-column label="促销名称" prop="name"></el-table-column>
                <el-table-column label="促销方式" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.type}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1E63F1" @click="editBtn(scope.row)">修改</el-button>
                        <el-button type="text" style="color: #FD4446" @click="delePromotion(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
<!--            <el-pagination-->
<!--                    class="operation-pagination"-->
<!--                    :current-page="adminPages.currentPageNum"-->
<!--                    :page-size="adminPages.eachPageNum"-->
<!--                    layout="sizes, prev, pager, next, total, jumper"-->
<!--                    :total="adminPages.total"-->
<!--                    @current-change="adminCurrentChange"-->
<!--            ></el-pagination>-->
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "SetPromotion",
        data(){
            return {
                tableData:[],
                editId: null,
                // adminPages: {
                //     currentPageNum: 1,
                //     eachPageNum: 10,
                //     total: 0
                // }
            }
        },
        mounted() {
            this.getSuitList()
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getSuitList() {
                let params = {
                    type: 3,
                }
                this.$http.axiosGetBy(this.$api.showStuMarket, params, (res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data[0].stu_market_data
                        this.editId = res.data.data[0].id;
                        if (this.$common.isCompetion()) {
                            let menus = this.menus;
                            let currentStatus = menus[1].children[2].status;
                            if (Number(currentStatus) === 0) {
                                this.operation_updateStatus(1, 2, 1);
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    // console.log(err)
                })
            },
            // adminCurrentChange(val) {
            //     this.adminPages.currentPageNum = val;
            //     // this.getTaskList()
            // },
            // 新建促销
            toPromotionAdd(){
                this.$router.push({
                    path: '/student/operationpromotion/setpromotionAdd'
                })
            },
            // 编辑
            editBtn(row) {
                console.log(this.editId)
                this.$router.push({
                    path: '/student/operationpromotion/setpromotionAdd',
                    query: {
                        id: this.editId
                    }
                })
            },
            // 结束推广
            endPromotion(){
                this.$confirm('确定要结束推广吗？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGet(this.$api.endStudentMarket, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {
                    this.$message.warning('已取消结束')
                });
            },
            // 删除推广
            delePromotion(row){
                this.$confirm('是否删除该套装促销，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delStudentMarket, {id: row.id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getSuitList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
        color: #9A9A9A;
        font-size: 14px;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    .SetPromotion{
        .SetPromotionHeader{
            .headerLeft{
                display: flex;
                justify-content: space-between;
                p{
                    color: #333333;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 20px 0 0 0;
                }
                .headerLeftBtn{
                    .btn{
                        width: 100px;
                        height: 36px;
                        border-color: #FD4446;
                        line-height: 0;
                    }
                    .btn:nth-child(1){
                        background: #F7F9FB;
                        color: #FD4446;
                    }
                    .btn:nth-child(2){
                        background: #FD4446;
                        color: #ffffff;
                    }
                }
            }
        }
        .SetPromotionTable{
            margin-top: 18px;
            background: #ffffff;
            padding: 20px;
        }
    }
    /deep/ .el-button.is-disabled {
        color: #fff;
        background-color: #DCDCDC !important;
        border-color: #DCDCDC !important;
    }
</style>